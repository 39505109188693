import { Carousel3d, Slide } from 'vue-carousel-3d';
import { productImages } from '@/utils/mediaData';
import { BContainer, BRow, BCol } from 'bootstrap-vue';

export default {
  name: 'Slider',
  components: {
    Carousel3d,
    Slide,
    BContainer,
    BRow,
    BCol
  },
  data() {
    return {
      sliders: productImages.sliders
    };
  }
};
