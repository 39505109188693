import { designIcons } from '@/utils/mediaData';
import { isPC } from '@/utils/commonUtils';
import {
  BContainer,
  BRow,
  BCol,
  BIcon,
  BDropdownItem,
  BNav,
  BNavItem,
  BNavItemDropdown,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BButton
} from 'bootstrap-vue';

export default {
  name: 'CommonHeader',
  components: {
    BContainer,
    BRow,
    BCol,
    BIcon,
    BDropdownItem,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BSidebar,
    BListGroup,
    BListGroupItem,
    BButton
  },
  data() {
    return {
      productCategory: {},
      logo: designIcons.logo,
      isPC
    };
  },
  created() {
    this.getProductCategory();
  },
  methods: {
    getProductCategory() {
      const store = this.$store;
      const { productCategoryList } = store.state.app;

      this.productCategory = productCategoryList;
    }
  }
};
