<template>
  <div class="information" id="information-homepage">
    <b-container>
      <b-row>
        <b-col :sm="isMobile($store) ? 12 : 4">
          <b-row>
            <b-col cols="5" class="d-flex align-items-center">
              <img
                src="https://res.cloudinary.com/nitpen/image/upload/v1617723485/assets/theme/img/home-page/location-02_azqubu.png"
                class="rounded"
                alt=""
              />
            </b-col>
            <b-col cols="7" class="d-flex align-items-center px-0">
              <p class="media-text">
                87/53 Trần Phú, Phường 4, Quận 5, Hồ Chí Minh
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col :sm="isMobile($store) ? 12 : 4">
          <b-row>
            <b-col cols="5" class="d-flex align-items-center">
              <img
                src="https://res.cloudinary.com/nitpen/image/upload/v1617723484/assets/theme/img/home-page/call-02_rlfyrg.png"
                class="rounded"
                alt=""
              />
            </b-col>
            <b-col cols="7" class="d-flex align-items-center px-0">
              <p class="media-text">
                <a href="tel:0345026032" target="_blank" rel="noopener">
                  034.5026.032 </a
                ><br />
                <a href="tel:0363770920" target="_blank" rel="noopener">
                  036.3770.920
                </a>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col :sm="isMobile($store) ? 12 : 4">
          <b-row>
            <b-col cols="5" class="d-flex align-items-center">
              <img
                src="https://res.cloudinary.com/nitpen/image/upload/v1617723484/assets/theme/img/home-page/email-02_jxiht0.png"
                class="rounded"
                alt=""
              />
            </b-col>
            <b-col cols="7" class="d-flex align-items-center px-0">
              <p class="media-text">
                <a
                  href="mailto:ingiatot.print@gmail.com"
                  target="_blank"
                  rel="noopener"
                >
                  ingiatot.print@gmail.com
                </a>
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<style scoped>
.information {
  margin-top: 50px;
  background-color: #29aae2;
  padding: 50px 0;
  overflow: hidden;
}
.information img {
  width: 100%;
}
.media-text {
  margin-bottom: 0;
  color: #fff;
  text-align: left;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}
.media-text a {
  color: #fff;
}
.information .col-sm-12 {
  margin-bottom: 10px;
}
.information .col-sm-12:last-child {
  margin-bottom: 0;
}
.information .col-sm-12 img {
  width: 100px;
}
.information .col-sm-12 .media-text {
  font-size: 18px;
}
.information .col-sm-12 .media-text:last-child {
  padding-bottom: 0;
}
@media only screen and (max-width: 1024px) {
  .media-text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 850px) {
  .media-text {
    font-size: 11px;
  }
  .information img {
    width: 70px;
  }
}
@media only screen and (max-width: 500px) {
  .information {
    padding: 30px 0;
  }
  .information .col-sm-12 img {
    width: 80px;
  }
  .information .col-sm-12 .media-text {
    font-size: 14px;
  }
}
</style>
<script>
import { isMobile } from '@/utils/commonUtils';
import { BContainer, BCol, BRow, BMedia } from 'bootstrap-vue';

export default {
  name: 'Information',
  components: {
    BContainer,
    BCol,
    BRow,
    BMedia
  },
  data() {
    return { isMobile };
  }
};
</script>
