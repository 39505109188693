import CommonHeader from '../components/CommonHeader/CommonHeader.vue';
import Banner from './components/Banner/Banner.vue';
import Product from './components/Product/Product.vue';
import Slider from './components/Slider/Slider.vue';
import Information from '../HomePage/components/Information.vue';
import Copyright from '../components/Copyright/Copyright.vue';

export default {
  name: 'ProductPage',
  components: {
    CommonHeader,
    Banner,
    Product,
    Slider,
    Information,
    Copyright
  }
};
