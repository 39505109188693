import * as Sentry from '@sentry/vue';
import { RequestFactory } from '@Request/RequestFactory';
import { designIcons } from '@/utils/mediaData';
import { isMobile } from '@/utils/commonUtils';
import {
  BContainer,
  BRow,
  BCol,
  BIcon,
  BForm,
  BFormSelect,
  BPagination,
  BImgLazy
} from 'bootstrap-vue';

const defaultPerPage = 12;
const defaultPage = 1;
const ProductRequest = RequestFactory.get('products');
const translate = {
  all: 'Tất cả'
};

export default {
  name: 'Product',
  components: {
    BContainer,
    BRow,
    BCol,
    BIcon,
    BForm,
    BFormSelect,
    BPagination,
    BImgLazy
  },
  data() {
    return {
      productCategory: [],
      productTopic: [{ text: 'Chủ đề', value: null }],
      productTemplate: [],
      paginationModel: {},
      topicId: null,
      categoryName: null,
      logo: designIcons.logo,
      isMobile,
      translate
    };
  },
  created() {
    this.getProductCategory();
    this.fetchAllData();
  },
  watch: {
    $route(to, from) {
      this.fetchAllData();
    }
  },
  methods: {
    async fetchProductList(perPage, page) {
      const categorySlug = this.$route.params.category;
      let categoryId = null;

      this.productCategory.forEach(element => {
        if (element.slug === categorySlug) {
          categoryId = element.id;
          this.categoryName = element.name;

          // change route
          document.title = element.name + ' - ' + process.env.VUE_APP_TITLE;
          return true;
        }
      });

      try {
        const {
          data: { response }
        } = await ProductRequest.getProductsByCategoryAndTopic(
          categoryId,
          this.topicId,
          perPage,
          page
        );
        this.productTemplate = response.data;

        this.paginationModel = {
          currentPage: response.current_page,
          lastPage: response.last_page,
          perPage: response.per_page,
          total: response.total
        };
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async fetchTopicList() {
      try {
        const {
          data: { response }
        } = await ProductRequest.getTopics();

        response.forEach(element => {
          this.productTopic.push({
            text: element.name,
            value: element.id
          });
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async fetchAllData() {
      const loader = this.$loading.show();

      await this.fetchTopicList();
      await this.fetchProductList(defaultPerPage, defaultPage);

      this.$nextTick(function() {
        loader.hide();
      });
    },
    async fetchProductPagination(page) {
      const loader = this.$loading.show();
      await this.fetchProductList(this.paginationModel.perPage, page);

      this.$nextTick(function() {
        loader.hide();
        this.$scrollTo('#list-products', 1000);
      });
    },
    async fetchProductByTopic() {
      const loader = this.$loading.show();
      await this.fetchProductList(
        this.paginationModel.perPage,
        this.paginationModel.currentPage
      );

      this.$nextTick(function() {
        loader.hide();
        this.$scrollTo('#list-products', 1000);
      });
    },
    getProductCategory() {
      const store = this.$store;
      const { productCategoryList } = store.state.app;

      this.productCategory = productCategoryList;
    }
  }
};
