<template>
  <div id="product-banner" @click="scrollToListProduct">
    <b-img :src="banner1" fluid alt="Product banner"></b-img>
  </div>
</template>
<script>
import { productImages } from '@/utils/mediaData';
import { BImg } from 'bootstrap-vue';

export default {
  name: 'Banner',
  components: {
    BImg
  },
  data() {
    return {
      banner1: productImages.banner1
    };
  },
  methods: {
    scrollToListProduct() {
      document.getElementById('list-products').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};
</script>
