var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider mt-5 pb-4"},[_c('b-container',[_c('b-row',[_c('b-col',[_c('carousel-3d',{attrs:{"controls-visible":true,"controls-prev-html":'&#10092;',"controls-next-html":'&#10093;',"autoplay":true,"autoplay-timeout":5000,"height":360}},_vm._l((_vm.sliders),function(slide,i){return _c('slide',{key:i,attrs:{"index":i},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
var isCurrent = ref.isCurrent;
var leftIndex = ref.leftIndex;
var rightIndex = ref.rightIndex;
return [_c('img',{class:{
                  current: isCurrent,
                  onLeft: leftIndex >= 0,
                  onRight: rightIndex >= 0
                },attrs:{"data-index":index,"src":slide}})]}}],null,true)})}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }